import type { SuperHubRouteParams } from '../types';
import type { ReadChangelogCollectionType, ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type { HubResponseProps } from '@readme/iso';

import React from 'react';
import { useParams } from 'react-router-dom';

import ScrollTop from '@core/utils/ScrollTop';

import ConnectRoute from '../ConnectRoute';

import ChangelogList from './List';
import ChangelogPost from './Post';
import classes from './style.module.scss';
import './style.scss';

/**
 * Shape of route specific data for changelogs.
 */
export interface ChangelogRouteProps {
  apiDefinitions?: never;
  changelogs?: ReadChangelogCollectionType;
  document?: ReadChangelogType['data'];
}

export type TypeIconClasses = typeof typeIconClasses;

const typeIconClasses = {
  added: `icon-plus1 ${classes.ChangelogIcon_added}`,
  fixed: `icon-check1 ${classes.ChangelogIcon_fixed}`,
  improved: `icon-heart1 ${classes.ChangelogIcon_improved}`,
  deprecated: `icon-alert-triangle ${classes.ChangelogIcon_deprecated}`,
  removed: `icon-x ${classes.ChangelogIcon_removed}`,
} as const;

function Content({ changelogs, document }: HubResponseProps<ChangelogRouteProps>) {
  const { slug } = useParams<SuperHubRouteParams>();

  return (
    <>
      <ScrollTop smooth />
      {slug ? (
        <ChangelogPost initialChangelog={document} typeIconClasses={typeIconClasses} />
      ) : (
        <ChangelogList initialCollection={changelogs} typeIconClasses={typeIconClasses} />
      )}
    </>
  );
}

export default function Changelog(props: HubResponseProps<ChangelogRouteProps>) {
  return <ConnectRoute next={Content} props={props} />;
}
